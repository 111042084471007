import React from "react";
import { get } from "lodash-es";
import paths from "path";

import { hierarchize } from "./util/hierarchical";

const keyName = "key";
const pathName = "path";
const uniqueKeyName = "uniqueKey";

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey
    ? parentUniqueKey + "." + node[keyName]
    : node[keyName];

  const parentPath = get(parent, pathName, "");
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

const routeConfig = hierarchize({
  key: "home",
  name: "Home",
  icon: "Home",
  path: "/",
  component: React.lazy(() => import ("./components/pages/Dashboard")),
  children: [
    {
      key: "login",
      name: "Login",
      isPublic: true,
      isHidden: true,
      component: React.lazy(() => import ("./components/pages/Login"))
    }, {
      key: "profile",
      name: "Profile",
      isHidden: true,
      component: React.lazy(() => import ("./components/pages/Profile"))
    }, {
      key: "users/:userId",
      name: "User Details",
      isHidden: true,
      exact: true,
      component: React.lazy(() => import ("./components/pages/Users/UserDetails"))
    }, {
      key: "users",
      name: "Users",
      icon: "People",
      component: React.lazy(() => import ("./components/pages/Users"))
    }, {
      key: "classes/:classId",
      name: "Class Details",
      isHidden: true,
      component: React.lazy(() => import ("./components/pages/Classes/ClassDetails"))
    }, {
      key: "classes",
      name: "Classes",
      icon: "Weights",
      component: React.lazy(() => import ("./components/pages/Classes"))
    }, {
      key: "",
      name: "",
      icon: "",
      expanded: false,
      children: [
        {
          key: "rides/:rideId",
          name: "Ride Class Details",
          isHidden: true,
          component: React.lazy(() => import ("./components/pages/Rides/RideDetails"))
        }, {
          key: "rides",
          name: "Ride Classes",
          icon: "Cycling",
          component: React.lazy(() => import ("./components/pages/Rides"))
        }, {
          key: "virtual-rides/:rideId",
          name: "Virtual Ride Details",
          isHidden: true,
          component: React.lazy(() => import ("./components/pages/VirtualRides/VirtualRideDetails"))
        }, {
          key: "virtual-rides",
          name: "Virtual Rides",
          icon: "Vacation",
          component: React.lazy(() => import ("./components/pages/VirtualRides"))
        }, {
          key: "ratings",
          name: "Ride Ratings",
          icon: "6PointStar",
          component: React.lazy(() => import ("./components/pages/Ratings"))
        }
      ]
    }, {
      key: "",
      name: "",
      icon: "",
      expanded: false,
      children: [
        {
          key: "rows/:rowId",
          name: "Row Class Details",
          isHidden: true,
          component: React.lazy(() => import ("./components/pages/Rows/RowDetails"))
        }, {
          key: "rows",
          name: "Row Classes",
          icon: "Cycling",
          component: React.lazy(() => import ("./components/pages/Rows"))
        }, {
          key: "virtual-rows/:rideId",
          name: "Virtual Row Details",
          isHidden: true,
          component: React.lazy(() => import ("./components/pages/VirtualRows/VirtualRowDetails"))
        }, {
          key: "virtual-rows",
          name: "Virtual Rows",
          icon: "Vacation",
          component: React.lazy(() => import ("./components/pages/VirtualRows"))
        }, {
          key: "rower-ratings",
          name: "Rower Ratings",
          icon: "6PointStar",
          component: React.lazy(() => import ("./components/pages/RowerRatings"))
        }
      ]
    }, {
      key: "",
      name: "",
      icon: "",
      expanded: false,
      children: [
        {
          key: "ellipticals/:ellipticalId",
          name: "Elliptical Details",
          isHidden: true,
          component: React.lazy(() => import ("./components/pages/Ellipticals/EllipticalDetails"))
        }, {
          key: "ellipticals",
          name: "Elliptical Classes",
          icon: "Cycling",
          component: React.lazy(() => import ("./components/pages/Ellipticals"))
        }, {
          key: "virtual-ellipticals/:ellipticalId",
          name: "Virtual Elliptical Details",
          isHidden: true,
          component: React.lazy(() => import ("./components/pages/VirtualEllipticals/VirtualEllipticalDetails"))
        }, {
          key: "virtual-ellipticals",
          name: "Virtual Elliptical",
          icon: "Vacation",
          component: React.lazy(() => import ("./components/pages/VirtualEllipticals"))
        }, {
          key: "elliptical-ratings",
          name: "Elliptical Ratings",
          icon: "6PointStar",
          component: React.lazy(() => import ("./components/pages/EllipticalRatings"))
        }
      ]
    }, {
      key: "",
      name: "",
      icon: "",
      expanded: false,
      children: [
        {
          key: "treadmills/:treadmillId",
          name: "Treadmill Details",
          isHidden: true,
          component: React.lazy(() => import ("./components/pages/Treadmills/TreadmillDetails"))
        }, {
          key: "treadmills",
          name: "Treadmill Classes",
          icon: "Cycling",
          component: React.lazy(() => import ("./components/pages/Treadmills"))
        }, {
          key: "virtual-treadmills/:treadmillId",
          name: "Virtual Treadmill Details",
          isHidden: true,
          component: React.lazy(() => import ("./components/pages/VirtualTreadmills/VirtualTreadmillDetails"))
        }, {
          key: "virtual-treadmills",
          name: "Virtual Treadmill",
          icon: "Vacation",
          component: React.lazy(() => import ("./components/pages/VirtualTreadmills"))
        }, {
          key: "treadmill-ratings",
          name: "Treadmill Ratings",
          icon: "6PointStar",
          component: React.lazy(() => import ("./components/pages/TreadmillRatings"))
        }
      ]
    }, {
      key: "filters",
      name: "Filters",
      icon: "Filter",
      component: React.lazy(() => import ("./components/pages/Filters/Filters.jsx"))
    }, {
      key: "app-version",
      name: "App Version",
      icon: "AppIconDefaultAdd",
      component: React.lazy(() => import ("./components/pages/AppVersion"))
    }, {
      key: "inactive-users",
      name: "Inactive Users",
      icon: "PeopleAlert",
      component: React.lazy(() => import ("./components/pages/InactiveUsers"))
    }, {
      key: "admin",
      name: "Admin",
      icon: "Teamwork",
      component: React.lazy(() => import ("./components/pages/Admin"))
    }, {
      key: "challenges",
      name: "Challenges",
      icon: "Medal",
      component: React.lazy(() => import ("./components/pages/Challenges"))
    }, {
      key: "challenge/:challengeId",
      name: "Challenge Details",
      isHidden: true,
      component: React.lazy(() => import ("./components/pages/Challenges/ChallengeDetails"))
    }, {
      key: "programs",
      name: "Programs",
      icon: "AddEvent",
      component: React.lazy(() => import ("./components/pages/Programs"))
    }, {
      key: "metrics",
      name: "Subscriptions",
      icon: "Subscribe",
      component: React.lazy(() => import ("./components/pages/Subscriptions"))
    }, {
      key: "coach",
      name: "1-1 coach",
      icon: () =>
        <span style={{
          height: '20px',
          width: '20px',
          marginLeft: '16px',
          display: 'flex',
          marginRight: '14px'
        }}>
          <img src={require('./assets/coach.png')} alt="coach" />
        </span>,
      component: React.lazy(() => import ("./components/pages/Coach"))
    }, {
      key: "user-coaching-plan/:userId",
      name: "User Coaching Details",
      isHidden: true,
      exact: true,
      component: React.lazy(() => import ("./components/pages/Coach/UserCoachingDetails"))
    }, {
      key: "user-coaching-plan/:userId/meal-plan-editor/:mealPlanId",
      name: "User Meal Plan Editor",
      isHidden: true,
      exact: true,
      component: React.lazy(() => import ("./components/pages/Coach/MealPlanEditor"))
    }, {
      key: "user-coaching-plan/:userId/workout-plan-editor/:workoutPlanId",
      name: "User Workout Plan Editor",
      isHidden: true,
      exact: true,
      component: React.lazy(() => import ("./components/pages/Coach/WorkoutPlanEditor"))
    }
  ]
}, null, pathGenerator);

export default routeConfig;
