import React from 'react';
import { Stack, styled, classNamesFunction } from '@fluentui/react';
import { Sidebar } from '../Sidebar';
import { TopMenu } from '../TopMenu';

const getStyles = ({ theme }) => {
  return {
    root: {},
    sidebar: {},
    contentWrapper: {
      paddingLeft: theme.spacing.l2,
      paddingRight: theme.spacing.l2,
      paddingBottom: theme.spacing.l2,
      backgroundColor: '#f9f9f9',
      height: 'calc(100vh - 48px)',
      overflow: 'auto'
    }
  };
};

const getClassNames = classNamesFunction();

function MasterLayoutComponent({ children, theme, styles }) {
  const classNames = getClassNames(styles, { theme });
  const [isNavCollapsed, setIsNavCollapsed] = React.useState(false);

  React.useEffect(() => {
    setIsNavCollapsed(localStorage.getItem('NavToggler.isNavCollapsed'));
  }, []);
  
  const onNavToggle = (state) => {
    setIsNavCollapsed(state)
  }

  return (
    <Stack horizontal className={classNames.root}>
      <Stack.Item grow={false} className={classNames.sidebar}>
        <Sidebar onNavToggle={(state) => onNavToggle(state)} />
      </Stack.Item>
      <Stack.Item grow={true}>
        <TopMenu />
        <Stack className={classNames.contentWrapper} style={{width: isNavCollapsed === true ? 'calc(100vw - 48px)' : 'calc(100vw - 220px)'}}>
          <div style={{maxWidth: '100%'}}>{children}</div>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

export const MasterLayout = styled(MasterLayoutComponent, getStyles);
